import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { API } from "../../../core/_base/crud";
import { BaseSettingsModel } from "./base-settings.model";
import { IResponse } from "../../../core/_base/crud/interfaces/response-interface";
import { ProductModel } from "../products";
import { JobsEnum } from "./components/Economic-jobs-dialog/jobs-enum";
import { shareReplay } from "rxjs/operators";
import { JobParamsResponse } from "../job-response.interface";
import { ICreditroConfiguration } from "../contracts/contract-details/creditro-case/creditro-interfaces";

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.settings, product);
  }

  getProductById(productId: number | string): Observable<any> {
    return this.http.get<any>(API.settings + `/${productId}`);
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.post<any>(`${API.settings}/${productId}`, product);
  }

  getSettingsList(category_code): Observable<any> {
    return this.http.get<any>(`${API.settings}/getAll/list?category=${category_code}`);
  }

  getSystemTaksList(): Observable<any> {
    return this.http.get<any>(`${API.tasks}/getAll/list?is_system=1`);
  }

  getDepartmentsList(q): Observable<any> {
    return this.http.get<any>(`${API.departments}/getAll/list`, {
      params: {
        q,
      },
    });
  }

  assignTasksToDepartments(payload) {
    return this.http.post<any>(`${API.tasks}/assign_tasks_to_departments`, payload);
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=${category_code}`);
  }

  getContractAccountList(): Observable<any> {
    return this.http.get<any>(`${API.contractAccounts}/getAll/list`);
  }

  getAccounts(q): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=accounts&q=${q}`);
  }

  getCreditroConfiguration(): Observable<IResponse<ICreditroConfiguration>> {
    return this.http.get<IResponse<ICreditroConfiguration>>(`${API.lookups}/creditro-configurations`);
  }

  addContractAccounts(payload) {
    return this.http.post<any>(`${API.contractAccounts}`, payload);
  }

  getDepartments(q): Observable<any> {
    return this.http.get<any>(`${API.departments}/getAll/list`, {
      params: {
        q,
      },
    });
  }

  getCustomSettings(category_code): Observable<any> {
    return this.http.get<any>(`${API.settings}?category=${category_code}`);
  }

  getSetting(setting_code): Observable<any> {
    return this.http.get<any>(`${API.settings}/${setting_code}`);
  }

  updateCustomSettings(code: string, payload) {
    return this.http.post(`${API.settings}/${code}`, payload);
  }

  getDepartmentDetails(id: string) {
    return this.http.get(`${API.departments}/${id}`);
  }

  updateVariableInterest(payload) {
    return this.http.post(`${API.settings}/variable-interest`, payload);
  }

  updateMonthioSettings(payload) {
    return this.http.post(`${API.settings}/monthio-settings`, payload);
  }

  updateIncomeDocumentSettings(payload) {
    return this.http.post(`${API.settings}/income-document-expiration`, payload);
  }

  getMarkupContractCount() {
    return this.http.get(`${API.settings}/get-markup-count`);
  }

  getMonthioConfigurations(): Observable<any> {
    return this.http.get<any>(`${API.API_URL}/lookups/case-configurations`);
  }

  updateRequiredFields(payload) {
    return this.http.post(`${API.settings}/required-fields-settings`, payload);
  }

  updateTemplateCustomFields(payload) {
    return this.http.post(`${API.settings}/template-custom-fields-settings`, payload);
  }

  getAdjustmentProductSettings(): Observable<IResponse<BaseSettingsModel<ProductModel[]>>> {
    return this.http.get<IResponse<BaseSettingsModel<ProductModel[]>>>(`${API.settings}/product-adjustment-setting`);
  }

  updateAdjustmentProductSettings(payload) {
    return this.http.post(`${API.settings}/product-adjustment-setting`, payload);
  }

  executeJob(payload) {
    return this.http.post(`${API.settings}/execute-job`, payload);
  }

  $cachedEconomicJobs: Observable<IResponse<JobParamsResponse>> = this.http
    .get<IResponse<JobParamsResponse>>(`${API.settings}/economic-jobs`)
    .pipe(shareReplay());

  getEconomicJobs(): Observable<IResponse<JobParamsResponse>> {
    return this.$cachedEconomicJobs;
  }

  getJobParamData(job: JobsEnum): Observable<IResponse<string[]>> {
    return this.http.get<IResponse<string[]>>(`${API.settings}/job-params-data?job=${job}`);
  }

  updateCreditroSettings(payload): Observable<IResponse<void>> {
    return this.http.post<IResponse<void>>(`${API.settings}/creditro-settings`, payload);
  }

  getCorporateCustomerRealOwners(customerId: number): Observable<IResponse<any[]>> {
    return this.http.get<IResponse<any[]>>(`${API.lookups}/${customerId}/real-owners`);
  }

  updateFarpaySettings(payload) {
    return this.http.post(`${API.settings}/farpay-settings`, payload);
  }
}
